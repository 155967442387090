var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.lang.label)+" Section")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"title","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Title","name":"title","fou":""},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"subtitle","rules":"","name":"The Subtitle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Subtitle","name":"subtitle","fou":""},model:{value:(_vm.formData.subtitle),callback:function ($$v) {_vm.$set(_vm.formData, "subtitle", $$v)},expression:"formData.subtitle"}})]}}])})],1)]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Content")]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":_vm.editorConfig},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }